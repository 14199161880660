export default [
	{
		path: '/',
		name: 'index',
		component: () => import('../pages/layout'),
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/pages/login'),
		meta: {
			isAuth: false,
			title: '登录',
		},
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('@/views/Test'),
		meta: {
			isAuth: false,
			title: '测试',
		},
	},
];
