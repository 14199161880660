<template>
  <el-form
    ref="artisanForm"
    class="margin12"
    style="padding: 12px 20px 12px 0px"
    :model="formData"
    :rules="rules"
    :disabled="disabled"
    :size="size"
    :label-width="labelWidth"
    :label-position="labelPosition"
  >
    <slot :row="formData" name="header"></slot>
    <el-row :gutter="20">
      <el-col
        style="min-height: 50px;"
        :span="column.span || span"
        v-for="(column, index) in items"
        :key="index"
      >
        <el-form-item
          :prop="column.code"
          :label="column.name"
          :label-width="labelWidth"
        >
          <template #label>
            <el-tooltip :tabindex="-1" effect="dark" :content="column.name" placement="top">
              <span class="text-wrap">{{ column.name }}</span>
            </el-tooltip>
          </template>
          <slot
            :name="column.code + 'Form'"
            :row="formData"
            v-if="column.isSlot"
          ></slot>
          <template v-else>
            <component
              :is="inputTypes[column.inputType]"
              :column="column"
              v-model="formData[column.code]"
            ></component>
          </template>
        </el-form-item>
      </el-col>
    </el-row>
    <slot :row="formData" name="footer"></slot>
  </el-form>
</template>

<script>
import ArtisanRadio from "./ArtisanRadio.vue";
import ArtisanSwitch from "./ArtisanSwitch.vue";
import ArtisanCheckbox from "./ArtisanCheckbox.vue";
import ArtisanNumber from "./ArtisanNumber.vue";
import ArtisanPassword from "./ArtisanPassword.vue";
import ArtisanSelect from "./ArtisanSelect.vue";
import ArtisanMulSelect from "./ArtisanMulSelect.vue";
import ArtisanTreeSelect from "./ArtisanTreeSelect.vue";
import ArtisanMuTreeSelect from "./ArtisanMuTreeSelect.vue";
import ArtisanInput from "./ArtisanInput.vue";
import ArtisanText from "./ArtisanText.vue";
import ArtisanTime from "./ArtisanTime.vue";
import ArtisanDatetime from "./ArtisanDatetime.vue";
import ArtisanYear from "./ArtisanYear.vue";
import ArtisanMonth from "./ArtisanMonth.vue";
import ArtisanDate from "./ArtisanDate.vue";
import { getMeta } from "@/api/sys/meta";
export default {
  components: {
    ArtisanRadio,
    ArtisanSwitch,
    ArtisanCheckbox,
    ArtisanNumber,
    ArtisanPassword,
    ArtisanSelect,
    ArtisanMulSelect,
    ArtisanTreeSelect,
    ArtisanMuTreeSelect,
    ArtisanInput,
    ArtisanText,
    ArtisanTime,
    ArtisanDatetime,
    ArtisanYear,
    ArtisanMonth,
    ArtisanDate,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    span: {
      type: Number,
      default: 12,
    },
    size: { type: String, default: "mini" },
    labelWidth: { type: String, default: "160px" },
    labelPosition: { type: String, default: "right" },
    value: {
      type: Object,
      default: () => {
        return null;
      },
    },
    metaKey: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    metaLoaded: Function,
  },
  data() {
    return {
      formData: {},
      items: this.columns,
      inputTypes: {
        radio: "ArtisanRadio",
        switch: "ArtisanSwitch",
        checkbox: "ArtisanCheckbox",
        input: "ArtisanInput",
        textarea: "ArtisanText",
        number: "ArtisanNumber",
        password: "ArtisanPassword",
        datetime: "ArtisanDatetime",
        dateyear: "ArtisanYear",
        month: "ArtisanMonth",
        date: "ArtisanDate",
        localdate: "ArtisanDate",
        time: "ArtisanTime",
        select: "ArtisanSelect",
        mutiselect: "ArtisanMulSelect",
        treeselect: "ArtisanTreeSelect",
        treemutiselect: "ArtisanMuTreeSelect",
        icon: "icon",
        upload: "upload",
      },
    };
  },
  computed: {
    rules() {
      let rules = {};
      this.items.forEach((column) => {
        rules[column.code] = [];
        if (column.validator) {
          let vs = JSON.parse(column.validator);
          if (Array.isArray(vs))
            rules[column.code] = JSON.parse(column.validator);
          else rules[column.code].push(vs);
        }
        if (column.required) {
          rules[column.code].push({
            required: true,
            message: `${column.name}必填！`,
            trigger: "blur",
          });
        }
      });
      return rules;
    },
  },
  watch: {
    value: {
      handler() {
        this.formData = this.value || {};
      },
      deep: true,
      immediate: true,
    },
    formData: {
      handler() {
        this.$emit("input", this.formData);
      },
      deep: true,
    },
    columns() {
      this.items = this.columns;
    },
  },
  created() {
    if (this.metaKey) {
      getMeta(this.metaKey)
        .then((res) => {
          let data = res.data.data;
          if (this.metaLoaded && typeof this.metaLoaded == "function") {
            this.metaLoaded(data);
          }
          this.items = data.columns;
          this.initDefault();
        })
        .catch(() => {});
    } else {
      this.initDefault();
    }
  },
  methods: {
    initDefault() {
      this.items.forEach((item) => {
        if (!Object.prototype.hasOwnProperty.call(this.formData, item.code)) {
          if (item.dataType == "bool") {
            this.formData[item.code] = item.defValue ? true : false;
          } else {
            if (item.defValue)
              this.$set(this.formData, item.code, item.defValue);
          }
        }
      });
    },
    validate(func) {
      this.$refs.artisanForm.validate(func);
    },
    reset() {
      this.$refs.artisanForm.resetFields();
    },
  },
};
</script>

<style></style>
