<template>
  <base-container ref="container" :isLoading="tableLoading">
    <div class="layout-column" style="height: 100%">
      <div style="overflow: auto; margin: 5px">
        <ArtisanGridSearch
          v-if="queryPosition == 'top' && searchColumns.length > 0"
          class="back-white"
          ref="gridSearch"
          :span="8"
          :columns="searchColumns"
          @search="search"
          v-model="formQuery"
        >
          <template slot="header">
            <slot name="searchHeader"></slot>
          </template>
          <template
            v-for="(item, index) in searchColumns.filter((c) => c.searchSlot)"
            slot-scope="{ column, data }"
            :slot="item.code + 'Search'"
          >
            <div :key="index">
              <slot
                :data="data"
                :column="column"
                :name="item.code + 'Search'"
              ></slot>
            </div>
          </template>
          <template slot="footer">
            <slot name="searchFooter"></slot>
          </template>
        </ArtisanGridSearch>
      </div>
      <div class="layout-row" style="flex: 1; overflow: hidden; margin: 0 5px">
        <div
          v-if="leftPanel.width"
          :style="{ 'flex-basis': leftPanel.width }"
          style="width: 0; height: 100%; overflow: auto; margin-right: 5px"
        >
          <slot name="leftAside">
            <artisan-group style="height: 100%; border: none; overflow-y: auto">
              <div slot="header">
                <span v-text="leftPanel.title"></span>
              </div>
              <el-tree
                :data="leftPanel.data"
                :node-key="leftPanel.nodeKey"
                :default-expanded-keys="leftPanel.expandKeys"
                ref="tree"
                highlight-current
                :props="leftPanel.defaultProps"
                :expand-on-click-node="false"
                @current-change="treeChange"
              >
              </el-tree>
            </artisan-group>
          </slot>
        </div>
        <div class="layout-column" style="flex: 1">
          <div style="overflow: auto; margin-bottom: 5px">
            <slot name="toolHeader"></slot>
            <div style="display: flex; align-items: center">
              <slot name="gridBtnBefore"></slot>
              <template v-for="(btn, bi) in menuBtns">
                <el-button
                  v-if="isShowMenuBtn(btn)"
                  :disabled="btn.disabled || false"
                  :key="bi"
                  :type="btn.type || 'primary'"
                  :style="{
                    backgroundColor: btn.backColor,
                    color: btn.color,
                    borderColor: btn.borderColor,
                  }"
                  size="small"
                  :icon="btn.icon"
                  @click="btnClick(btn)"
                >
                  {{ btn.name }}
                </el-button>
              </template>
              <slot name="gridBtnAfter"></slot>
            </div>
            <div class="back-white">
              <slot name="toolFooter"></slot>
            </div>
          </div>
          <slot name="list" :data="tableData">
            <el-table
              style="flex: 1"
              :data="tableData"
              :row-key="getRowKey"
              :size="tableOption.Size || 'medium'"
              :lazy="vaildData(tableOption.lazy, false)"
              :load="treeLoad"
              :tree-props="tableOption.treeProps || {}"
              :expand-row-keys="tableOption.expandRowKeys"
              :default-expand-all="defaultExpandAll"
              :highlight-current-row="
                vaildData(tableOption.highlightCurrentRow, true)
              "
              @current-change="currentRowChange"
              @expand-change="expandChange"
              @header-dragend="headerDragend"
              :show-summary="tableOption.showSummary"
              :summary-method="tableSummaryMethod"
              :span-method="tableSpanMethod"
              :stripe="vaildData(tableOption.stripe, true)"
              :show-header="tableOption.showHeader"
              :default-sort="tableOption.defaultSort"
              @row-click="rowClick"
              @row-dblclick="rowDblclick"
              @cell-mouse-enter="cellMouseEnter"
              @cell-mouse-leave="cellMouseLeave"
              @cell-click="cellClick"
              @header-click="headerClick"
              @row-contextmenu="rowContextmenu"
              @header-contextmenu="headerContextmenu"
              @cell-dblclick="cellDblclick"
              :row-class-name="rowClassName"
              :cell-class-name="cellClassName"
              :header-row-class-name="headerRowClassName"
              :row-style="rowStyle"
              :cell-style="getCellStyle"
              :sort-method="sortMethod"
              :sort-orders="sortOrders"
              :sort-by="sortBy"
              :fit="tableOption.fit"
              :header-cell-class-name="headerCellClassName"
              :max-height="tableOption.maxHeight"
              height="100%"
              ref="grid"
              :width="vaildData(tableOption.width, '100%')"
              :border="tableOption.border"
              @selection-change="selectionChange"
              @select="select"
              @select-all="selectAll"
              @sort-change="sortChange"
            >
              <el-table-column
                v-if="tableOption.rowType == 'selection'||tableOption.rowType == 'treemutiselect'"
                type="selection"
                :selectable="tableOption.selectable"
                :reserve-selection="
                  vaildData(tableOption.reserveSelection, false)
                "
                :width="tableOption.selectionWidth || 50"
                :fixed="vaildData(tableOption.selectionFixed, 'left')"
                align="center"
              ></el-table-column>
              <el-table-column
                v-else-if="tableOption.rowType == 'index'"
                :label="tableOption.indexLabel || '#'"
                type="index"
                :width="tableOption.indexWidth || 50"
                :index="indexMethod"
                :fixed="vaildData(tableOption.indexFixed, 'left')"
                align="center"
              ></el-table-column>
              <el-table-column
                v-if="tableOption.rowType == 'expand'"
                type="expand"
                :width="tableOption.expandWidth || 60"
                :fixed="vaildData(tableOption.expandFixed, 'left')"
                align="center"
              >
                <template slot-scope="props">
                  <slot :row="props.row" name="expand"></slot>
                </template>
              </el-table-column>
              <template v-for="(column, index) in columns">
                <ArtisanGridItem
                  v-if="column.isView && column.isValid"
                  :column="column"
                  :key="index"
                >
                  <template slot-scope="scope" :slot="column.code">
                    <slot
                      :row="scope.row"
                      :label="scope.label"
                      :name="column.code"
                    ></slot>
                  </template>
                </ArtisanGridItem>
              </template>
              <el-table-column
                v-if="rowBtns.length > 0"
                :width="rowOperationWidth"
                label="操作"
                fixed="right"
                align="center"
                class-name="back-white"
                label-class-name="color-primary"
              >
                <template slot-scope="props">
                  <artisan-grid-row-btn
                    :btns="rowBtns.filter((c) => isShowRowBtn(props.row, c))"
                    @click="btnClick($event, props.row)"
                  ></artisan-grid-row-btn>
                </template>
              </el-table-column>
            </el-table>
          </slot>
          <el-row
            ref="mainFooter"
            type="flex"
            justify="space-between"
            v-if="tableOption.isPage"
            align="middle"
          >
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page.current || 1"
              :page-sizes="[10, 50, 100]"
              :page-size="page.size || 10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page.total || 0"
            ></el-pagination>
            <div>
              <slot name="pageRight"></slot>
            </div>
          </el-row>
          <slot name="bottom"></slot>
        </div>
        <div
          v-if="rightWidth"
          :style="{ 'flex-basis': rightWidth }"
          style="flex-basis: 25%; height: 100%; overflow: auto; margin: 0 5px"
        >
          <slot name="rightAside"></slot>
        </div>
      </div>
      <slot name="mainFooter"></slot>
    </div>
    <artisan-dialog
      ref="formDialog"
      :title="dialog.title"
      :url="dialog.url"
      :visible.sync="dialog.isShow"
      :isLoading="dialog.loading"
      :width="dialog.width"
      :height="dialog.height"
      @dialog-close="dialog.dialogClose"
    >
      <slot v-if="dialog.slot"></slot>
      <template v-else>
        <ArtisanForm
          ref="gridForm"
          v-if="dialog.isShow"
          v-model="formModel"
          :columns="formColumns"
        >
          <template slot-scope="{ row }" slot="header">
            <slot :row="row" name="formHeader"></slot>
          </template>
          <template v-for="(item, index) in formColumns">
            <template :slot="item.code + 'Form'">
              <div v-if="item.isSlot" :key="index">
                <slot
                  :row="formModel"
                  :column="item"
                  :name="item.code + 'Form'"
                ></slot>
              </div>
            </template>
          </template>
          <template slot-scope="{ row }" slot="footer">
            <slot :row="row" name="formFooter"></slot>
          </template>
        </ArtisanForm>
      </template>
      <template v-if="dialog.isFooter" #footer>
        <el-button
          type="danger"
          size="mini"
          icon="el-icon-close"
          @click="dialog.isShow = false"
          >{{ dialog.cancelText }}
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-check"
          @click="confirm"
          >{{ dialog.confirmText }}
        </el-button>
      </template>
    </artisan-dialog>
  </base-container>
</template>

<script>
import { mapGetters } from "vuex";
import { vaildData, buildTree } from "@/util/util";
import ArtisanGridItem from "./ArtisanGridItem";
import ArtisanForm from "../ArtisanForm";
import ArtisanGridSearch from "./ArtisanGridSearch";
import ArtisanGridRowBtn from "./ArtisanGridRowBtn";
import { getMeta, list, page, add, edit, remove, loadEnum } from "./grid";
export default {
  name: "grid",
  components: {
    ArtisanGridItem,
    ArtisanForm,
    ArtisanGridSearch,
    ArtisanGridRowBtn,
  },
  inject: ["index"],
  provide() {
    return {
      grid: this,
    };
  },
  props: {
    metaKey: {
      type: String,
      default: "",
    },
    metaData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    query: {
      type: Object,
      default: function () {
        return {};
      },
    },
    queryPosition: {
      type: String,
      default: "top",
    },
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    leftOption: {
      type: Object,
      default: function () {
        return {};
      },
    },
    rightWidth: {
      type: String,
      default: "",
    },
    removeMsg: {
      type: String,
      default: "确认删除？",
    },
    isTree: {
      type: Boolean,
      default: false,
    },
    defaultExpandAll: {
      type: Boolean,
      default: false,
    },
    opColumnWidth: {
      type: Number,
      default: 0,
    },
    summaryMethod: Function,
    spanMethod: Function,
    rowClassName: Function,
    cellClassName: Function,
    rowStyle: Function,
    cellStyle: Function,
    sortMethod: Function,
    sortOrders: Function,
    sortBy: Function,
    headerRowClassName: {
      type: Function,
      default: () => {
        return "back-gray1";
      },
    },
    headerCellClassName: {
      type: Function,
      default: () => {
        return "back-gray1";
      },
    },
    searchInited: Function,
    metaInited: Function,
    showDialogBefore: Function,
    showBefore: Function,
    confirmBefore: {
      type: Function,
      default: () => {
        return true;
      },
    },
    confirmAfter: Function,
    delRowBefore: Function,
    delRowAfter: Function,
    rowBtnPermission: Function,
    menuBtnPermission: Function,
    handleQuery: Function,
    handleData: Function,
    treeSearch: Function,
  },
  data() {
    return {
      tableLoading: false,
      tableOption: {
        columns: [],
      },
      tableData: [],
      tableCurrentKey: "",
      tableSelectRows: [],
      formQuery: Object.assign({}, this.query),
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      formModel: this.formData,
      leftPanel: {
        data: this.leftOption.data || [],
        width: this.leftOption.width || "",
        title: this.leftOption.title || "分类",
        expandKeys: [],
        searchField: this.leftOption.searchField || "",
        nodeKey: this.leftOption.nodeKey || "value",
        defaultProps: {
          children: this.leftOption.children || "children",
          label: this.leftOption.label || "label",
        },
      },
      dialog: {
        title: "",
        isShow: false,
        isSlot: false,
        loading: false,
        url: "",
        type: "add",
        isFooter: true,
        confirmText: "保存",
        cancelText: "取消",
        height: "75%",
        width: "75%",
        dialogClose: () => {},
      },
    };
  },
  computed: {
    ...mapGetters(["user", "btns"]),
    rowKey() {
      return this.tableOption.rowKey || "id";
    },
    columns() {
      return this.tableOption.columns || [];
    },
    menuBtns() {
      let btns = [];
      if (this.tableOption.btns && this.tableOption.btns.length > 0) {
        if (this.user.isSuper) {
          btns = this.tableOption.btns.filter((f) => f.menuType == "menuBtn");
        } else {
          btns = this.tableOption.btns.filter(
            (f) => f.menuType == "menuBtn" && this.btns[f.code]
          );
        }
      }
      return btns;
    },
    rowBtns() {
      let btns = [];
      if (this.tableOption.btns && this.tableOption.btns.length > 0) {
        if (this.user.isSuper) {
          btns = this.tableOption.btns.filter((f) => f.menuType == "rowBtn");
        } else {
          btns = this.tableOption.btns.filter(
            (f) => f.menuType == "rowBtn" && this.btns[f.code]
          );
        }
      }
      return btns;
    },
    rowOperationWidth() {
      if (this.opColumnWidth) return this.opColumnWidth;
      let fontWidth = 0;
      if (this.rowBtns.length >= 4) {
        fontWidth = 300;
      } else if (this.rowBtns.length > 2) {
        fontWidth = 230;
      } else {
        fontWidth = 170;
      }
      return fontWidth;
    },
    searchColumns() {
      if (this.tableOption.columns && this.tableOption.columns.length > 0) {
        return this.tableOption.columns.filter((f) => f.isSearch);
      }
      return [];
    },
    formColumns() {
      if (this.tableOption.columns && this.tableOption.columns.length > 0) {
        switch (this.dialog.type) {
          case "add":
            return this.tableOption.columns.filter((f) => f.isAdd);
          case "edit":
            return this.tableOption.columns.filter((f) => f.isEdit);
          case "view":
            return this.tableOption.columns.filter((f) => f.isView);
        }
      }
      return [];
    },
    isView() {
      return this.dialog.type == "view";
    },
    columnMap() {
      let mapObj = {};
      if (this.tableOption.columns && this.tableOption.columns.length > 0) {
        this.tableOption.columns.forEach((item) => {
          mapObj[item.code] = item;
        });
      }
      return mapObj;
    },
  },
  watch: {
    leftOption: {
      handler: function () {
        this.initLeftPanel();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.tableLoading = true;
    if (this.metaData) {
      this.tableOption = this.handleMeta(this.metaData);
      this.loadData();
    } else {
      if (this.metaKey) {
        getMeta(this.metaKey)
          .then((res) => {
            this.tableOption = this.handleMeta(res.data.data);
            this.loadData();
          })
          .catch(() => {
            this.tableLoading = false;
          });
      }
    }
  },
  updated() {
    this.$nextTick(() => {
      //解决table 行样式错乱问题（莫名其妙出现的）
      if (this.$refs.grids) this.$refs.grid.doLayout();
    });
  },
  methods: {
    vaildData,
    handleMeta(option) {
      let lastViewIndex = 0;
      option.columns.forEach((c, i) => {
        if (c.isView) lastViewIndex = i;
        c.dicData = [];
        if (c.isValid && c.dicKey) {
          loadEnum(c.dicKey)
            .then((res) => {
              c.dicData = res.data.data;
            })
            .catch(() => {});
        }
      });
      if (option.columns && option.columns.length) {
        let last = option.columns[lastViewIndex];
        last.minWidth = last.width;
        last.width = "";
      }
      if (option.btns) {
        option.btns.forEach((btn) => {
          btn.isTab = false;
          if (btn.menuOption) {
            Object.assign(btn, JSON.parse(btn.menuOption));
          }
        });
      }
      if (typeof this.metaInited === "function") this.metaInited(option);
      return option;
    },
    reloadDicByCol(code) {
      let column = this.tableOption.columns.find((c) => c.code == code);
      if (column && column.dicKey) {
        column.dicData = [];
        loadEnum(column.dicKey)
          .then((res) => {
            column.dicData = res.data.data;
          })
          .catch(() => {});
      }
    },
    initLeftPanel() {
      if (this.leftOption.treeKey) {
        loadEnum(this.leftOption.treeKey)
          .then((res) => {
            let root = { value: "", label: "所有", children: [] };
            root.children = buildTree(res.data.data, {
              idField: "value",
            });
            root.children.forEach((c) => {
              this.leftPanel.expandKeys.push(c.value);
            });
            console.log(this.leftPanel.expandKeys);
            this.leftPanel.data = [root];
          })
          .catch(() => {});
      } else {
        this.leftPanel.data = this.leftOption.data || [];
      }
    },
    treeChange(data) {
      let obj = {};
      if (this.treeSearch && typeof this.treeSearch == "function") {
        this.treeSearch(data, obj);
      } else {
        if (this.leftPanel.searchField) {
          obj[this.leftPanel.searchField] = data[this.leftPanel.nodeKey];
        }
      }

      this.reload(obj);
    },
    reload(obj) {
      if (obj) Object.assign(this.query, obj);
      this.page.current = 1;
      this.loadData();
    },
    loadData() {
      this.tableLoading = true;
      let dataQuery = {
        orderBy: this.tableOption.orderBy,
        isDesc: this.tableOption.isDesc,
        filterParams: [],
      };
      let queryObj = Object.assign({}, this.formQuery, this.query);
      if (this.handleQuery) {
        queryObj = this.handleQuery(queryObj);
      }
      for (let key in queryObj) {
        let val = queryObj[key];
        let column = this.columnMap[key];
        if (val !== "" || val !== undefined) {
          dataQuery.filterParams.push({
            name: key,
            type: column ? column.searchType : "",
            value: val,
          });
        }
      }
      if (!this.tableOption.isPage) {
        return list(this.tableOption.baseUrl, dataQuery)
          .then((res) => {
            this.tableLoading = false;
            let data = res.data.data;
            if (this.handleData) {
              this.handleData(data);
            }
            if (this.isTree) {
              this.tableData = buildTree(data);
              this.tableOption.expandRowKeys = this.tableData.map(
                (c) => c[this.rowKey]
              );
              this.tableData.forEach((t) => {
                if (t.children) {
                  t.children
                    .map((c) => c[this.rowKey])
                    .forEach((t) => {
                      this.tableOption.expandRowKeys.push(t);
                    });
                }
              });
            } else {
              this.tableData = data;
            }
          })
          .catch(() => {
            this.tableLoading = false;
          });
      } else {
        dataQuery.current = this.page.current;
        dataQuery.size = this.page.size;
        return page(this.tableOption.baseUrl, dataQuery)
          .then((res) => {
            this.tableLoading = false;
            const rd = res.data.data;
            let data = rd.records;
            if (this.handleData) {
              this.handleData(data);
            }
            this.tableData = data;
            this.page.total = rd.total;
          })
          .catch(() => {
            this.tableLoading = false;
          });
      }
    },
    disableBtn(btnCode, disabled) {
      let btn = this.menuBtns.find((c) => c.code == btnCode);
      if (btn) {
        btn.disabled = disabled;
      }
    },
    getColumn(code) {
      return this.columns.find((c) => c.code == code);
    },
    btnClick(btn, row) {
      let btnOp = this.getBtnOption(btn, row);
      if (btn.code == this.metaKey + "_add") {
        this.toAdd(btnOp, row || {});
      } else if (btn.code == this.metaKey + "_edit") {
        this.toEdit(row, btnOp);
      } else if (btn.code == this.metaKey + "_del") {
        this.remove(row);
      } else if (btn.code == this.metaKey + "_batchDel") {
        this.batchRemove();
      } else if (btn.url) {
        if (btnOp.isTab) {
          this.index.addTab({
            name: btnOp.title,
            icon: btnOp.icon,
            url: btnOp.url,
          });
        } else {
          this.dialog.title = btnOp.title;
          this.dialog.url = btnOp.url;
          this.dialog.type = btnOp.code;
          this.dialog.isFooter = false;
          this.dialog.width = btnOp.popWidth || "75%";
          this.dialog.height = btnOp.popHeight || "75%";
          if (this.showDialogBefore) {
            this.showDialogBefore(this.dialog, this.formModel, btnOp);
          } else {
            this.dialog.isShow = true;
          }
        }
      } else {
        this.$emit(btn.code, row, btn);
      }
    },
    btnClickByCode(code, row) {
      let btn = this.tableOption.btns.find((c) => c.code == code);
      if (btn) {
        this.btnClick(btn, row);
      }
    },
    getBtnOption(btn, row) {
      let btnOp = {
        ...btn,
      };
      if (btnOp.title) {
        let arr = btnOp.title.match(/\{\w+\}/gm);
        if (arr) {
          for (let i = 0; i < arr.length; i++) {
            let item = arr[i];
            let fieldName = item.substring(1, item.length - 1);
            let fv = row[fieldName];
            if (fv) {
              btnOp.title = btnOp.title.replace(item, fv);
            } else {
              btnOp.title = btnOp.title.replace(item, "");
            }
          }
        }
      } else {
        btnOp.title = btnOp.name;
      }
      if (btnOp.params) {
        let arr = btnOp.params.match(/\{\w+\}/gm);
        if (arr) {
          let params = btnOp.params;
          for (let i = 0; i < arr.length; i++) {
            let item = arr[i];
            let fieldName = item.substring(1, item.length - 1);
            let fv = row[fieldName];
            if (fv) {
              params = params.replace(item, fv);
            } else {
              params = params.replace(item, "");
            }
          }
          btnOp.url += "?" + params;
        }
      }
      return btnOp;
    },
    toAdd(btn, data) {
      let tmpFormData = data || {};
      this.dialog.title = btn.title || btn.name;
      this.dialog.url = "";
      this.dialog.type = "add";
      this.dialog.isFooter = true;
      this.dialog.width = btn.popWidth || "75%";
      this.dialog.height = btn.popHeight || "75%";
      if (this.showDialogBefore) {
        this.showDialogBefore(this.dialog, tmpFormData, btn);
        Object.assign(tmpFormData, this.query);
      } else {
        Object.assign(tmpFormData, this.query);
        this.dialog.isShow = true;
      }
      this.formModel = tmpFormData;
    },
    toEdit(row, btn) {
      this.formModel = { ...row };
      this.dialog.title = btn.title;
      this.dialog.url = "";
      this.dialog.type = "edit";
      this.dialog.isFooter = true;
      this.dialog.width = btn.popWidth || "75%";
      this.dialog.height = btn.popHeight || "75%";
      if (this.showDialogBefore) {
        this.showDialogBefore(this.dialog, this.formModel, btn);
      } else {
        this.dialog.isShow = true;
      }
    },
    remove(row) {
      this.$refs.container
        .confirm({ msg: this.removeMsg })
        .then(() => {
          this.tableLoading = true;
          if (this.delRowBefore) this.delRowBefore(row);
          remove(this.tableOption.baseUrl, row[this.rowKey])
            .then(() => {
              this.tableLoading = false;
              this.$message({
                message: "操作成功！",
                type: "success",
              });
              this.loadData();
              if (this.delRowAfter) this.delRowAfter(row);
            })
            .catch(() => {
              this.tableLoading = false;
            });
        })
        .catch(() => {});
    },
    batchRemove() {
      if (this.tableSelectRows.length == 0) {
        this.$refs.container.alert({ msg: "请选择要删除的数据！" });
        return;
      }
      if (this.delRowBefore) this.delRowBefore(this.tableSelectRows);
      const ids = this.tableSelectRows.map((c) => c[this.rowKey]);
      this.$refs.container.confirm({ msg: this.removeMsg }).then(() => {
        this.tableLoading = true;
        remove(this.tableOption.baseUrl, ids.join(","))
          .then(() => {
            this.tableLoading = false;
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.loadData();
            if (this.delRowAfter) this.delRowAfter(this.tableSelectRows);
          })
          .catch(() => {
            this.tableLoading = false;
          });
      });
    },
    confirm() {
      const val = this.confirmBefore(this.dialog, this.formModel);
      if (!val) return;
      this.$refs.gridForm.validate((valid) => {
        if (valid) {
          this.$refs.formDialog
            .confirm({ msg: `确认${this.dialog.title}?` })
            .then(() => {
              let promise = this.dialog.type == "add" ? add : edit;
              this.dialog.loading = true;
              promise(this.tableOption.baseUrl, this.formModel)
                .then(() => {
                  this.$message({
                    message: "操作成功！",
                    type: "success",
                  });
                  this.dialog.isShow = false;
                  this.dialog.loading = false;
                  this.loadData();
                  if (this.confirmAfter)
                    this.confirmAfter(this.dialog, this.formModel);
                })
                .catch(() => {
                  this.dialog.loading = false;
                });
            })
            .catch(() => {});
        }
      });
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.loadData();
    },
    search() {
      //优化先选择页面后，再模糊搜索页面没重置的问题
      this.page.current = 1;
      this.loadData();
    },
    indexMethod(index) {
      return (
        index +
        1 +
        ((this.page.currentPage || 1) - 1) * (this.page.pageSize || 10)
      );
    },
    getRowKey(row) {
      const rowKey = row[this.rowKey];
      return rowKey;
    },
    isShowRowBtn(row, btn) {
      if (this.rowBtnPermission) {
        return this.rowBtnPermission(row, btn);
      }
      return true;
    },
    isShowMenuBtn(btn) {
      if (this.menuBtnPermission) {
        return this.menuBtnPermission(btn);
      }
      return true;
    },
    //树懒加载
    treeLoad(tree, treeNode, resolve) {
      this.$emit("tree-load", tree, treeNode, (data) => {
        tree.children = data;
        resolve(data);
      });
    },
    currentRowChange(currentRow, oldCurrentRow) {
      this.tableCurrentKey = currentRow.id;
      this.$emit("current-row-change", currentRow, oldCurrentRow);
    },
    expandChange(row, expand) {
      this.$emit("expand-change", row, expand);
    },
    headerDragend(newWidth, oldWidth, column, event) {
      this.$emit("header-dragend", newWidth, oldWidth, column, event);
    },
    tableSummaryMethod(param) {
      if (typeof this.summaryMethod === "function")
        return this.summaryMethod(param);
    },
    tableSpanMethod(param) {
      if (typeof this.spanMethod === "function") return this.spanMethod(param);
    },
    getCellStyle({ row, column }) {
      if (this.cellStyle) {
        return this.cellStyle(row, column);
      } else {
        if (column.property && row.id == this.tableCurrentKey) {
          return { backgroundColor: "#B3D8FF" };
        }
      }
    },
    rowClick(row, event, column) {
      this.$emit("row-click", row, event, column);
    },
    rowDblclick(row, event) {
      this.$emit("row-dblclick", row, event);
    },
    //当单元格 hover 进入时会触发该事件
    cellMouseEnter(row, column, cell, event) {
      this.$emit("cell-mouse-enter", row, column, cell, event);
    },
    //当单元格 hover 退出时会触发该事件
    cellMouseLeave(row, column, cell, event) {
      this.$emit("cell-mouse-leave", row, column, cell, event);
    },
    //当某个单元格被点击时会触发该事件
    cellClick(row, column, cell, event) {
      this.$emit("cell-click", row, column, cell, event);
    },
    //	当某一列的表头被点击时会触发该事件
    headerClick(column, event) {
      this.$emit("header-click", column, event);
    },
    //当某一行被鼠标右键点击时会触发该事件
    rowContextmenu(row, column, event) {
      this.$emit("row-contextmenu", row, column, event);
    },
    //当某一列的表头被鼠标右键点击时触发该事件
    headerContextmenu(column, event) {
      this.$emit("header-contextmenu", column, event);
    },
    //当某个单元格被双击击时会触发该事件
    cellDblclick(row, column, cell, event) {
      this.$emit("cell-dblclick", row, column, cell, event);
    },
    // 选择回调
    selectionChange(val) {
      this.tableSelectRows = val;
      this.$emit("selection-change", this.tableSelectRows);
    },
    // 单个选择回调
    select(selection, row) {
      this.$emit("select", selection, row);
    },
    // 点击勾选全选 Checkbox
    selectAll(selection) {
      this.$emit("select-all", selection);
    },
    // 排序回调
    sortChange(val) {
      this.tableOption.orderBy = val.prop;
      this.tableOption.isDesc = val.order !== "ascending";
      this.reload();
      this.$emit("sort-change", val);
    },
  },
};
</script>

<style scoped></style>
