<template>
    <el-cascader
      style="width: 100%"
      v-model="selVal"
      :options="dicItems"
      :props="{
        checkStrictly: true,
        expandTrigger: 'hover',
        emitPath: false,
        multiple:true
      }"
      :clearable="!column.required"
      :placeholder="placeholder"
      @change="change"
    ></el-cascader>
  </template>
  
  <script>
  import { loadEnum } from "@/api/sys/dicType";
  import { buildTree } from "@/util/util";
  export default {
    model: {
      event: "change",
    },
    props: {
      value: String,
      column: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    watch: {
      value() {
        this.selVal = this.value;
      },
    },
    data() {
      return {
        selVal: this.value,
        dicItems: [],
      };
    },
    computed: {
      placeholder() {
        return this.column.placeholder || "请输入" + this.column.name;
      },
    },
    created() {
      if (this.column.dicData && this.column.dicData.length) {
        this.dicItems = this.dicItems = buildTree(this.column.dicData, {
          idField: "value",
        });
      } else {
        if (this.column.dicKey) {
          loadEnum(this.column.dicKey).then((res) => {
            this.dicItems = buildTree(res.data.data, {
              idField: "value",
            });
          }).catch(() => {});
        }
      }
    },
    methods: {
      change() {
        if (this.column.change && typeof this.column.change == "function") {
          this.column.change(this.selVal, this.column);
        }
        this.$emit("change", this.selVal, this.column);
      },
    },
  };
  </script>
  
  <style >
  </style>
  